import {
  of as observableOf,
  Observable,
  throwError as observableThrowError,
} from "rxjs";
import {
  Injectable,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
let counter = 0;

@Injectable() //maybe remove
export class UserService {
  apiRoot: string = environment.apiRoot;

  @Output() getChangedName: EventEmitter<any> = new EventEmitter();
  @Output() getNotificationCount: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadingHandler: EventEmitter<any> = new EventEmitter();
  private users = {
    nick: { name: "Nick Jones", picture: "assets/images/nick.png" },
    eva: { name: "Eva Moor", picture: "assets/images/eva.png" },
    jack: { name: "Jack Williams", picture: "assets/images/jack.png" },
    lee: { name: "Lee Wong", picture: "assets/images/lee.png" },
    alan: { name: "Alan Thompson", picture: "assets/images/alan.png" },
    kate: { name: "Kate Martinez", picture: "assets/images/kate.png" },
  };

  private userArray: any[];

  constructor(private http: HttpClient) {
    // this.userArray = Object.values(this.users);
  }

  getLocal() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
    });

    let params = new HttpParams();

    let url = this.apiRoot + "/localtudo";

    const options = {
      headers: headers,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest("POST", url, {}, options);

    return this.http.request(req);
  }

  fileUploadingFunction(name, file) {
    this.fileUploadingHandler.emit({ name, file });
  }
  uploadFile(name, files: FileList) {
    if (files.length == 0) {
      console.log("No file selected!");
      return;
    }

    let file: File = files[0];
    let formData = new FormData();
    formData.append("name", name);
    formData.append("image[]", file);

    let headers = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
    });

    let params = new HttpParams();

    let url = this.apiRoot + "/video";

    const options = {
      headers: headers,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest("POST", url, formData, options);

    return this.http.request(req);
  }
  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }
  refreshBar(): void {
    this.getChangedName.emit();
  }
  getCount(valor): void {
    this.getNotificationCount.emit(valor);
  }
  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return observableOf(this.userArray[counter]);
  }
}
