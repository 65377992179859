import { Component } from '@angular/core';
import { UserService } from '../../../@core/data/users.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  videoList = []
  openBody = true
  openDiv = false
  constructor(private userService: UserService) {
    userService.fileUploadingHandler.subscribe(result => this.fileUploadingHandler(result));
  }
  closeBody(opened) {
    this.openBody = opened
  }
  closeDiv(opened) {
    this.openDiv = opened
  }
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  fileUploadingHandler(result) {
    this.videoList.push({
      id: this.uuidv4(),
      name: result.name,
      file: result.file,
      percentage: 0,
      done: false,
      url: null
    })
    this.openDiv = true
    let arrayNumber = this.videoList.length - 1
    this.userService.uploadFile(result.name, result.file).subscribe((resp: HttpEvent<any>) => {
      console.log(resp)
      if (resp.type === HttpEventType.Response) {
          this.videoList[arrayNumber].percentage = 100;
          this.videoList[arrayNumber].done = true;
          this.videoList[arrayNumber].url = resp.body.data;
          console.log('Upload complete');
      }
      if (resp.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * resp.loaded / resp.total);
          if (this.videoList[arrayNumber].percentage < percentDone) {
            this.videoList[arrayNumber].percentage = percentDone;
          }
          console.log('Progress ' + percentDone + '%');
      } 
    })
  }
}
