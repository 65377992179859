import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { UserService } from "../../../@core/data/users.service";
import { AnalyticsService } from "../../../@core/utils/analytics.service";
import { environment } from "../../../../environments/environment";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  @Input() position = "normal";

  user: any;

  userMenu = [
    { title: "Perfil", link: "/pages/perfil/perfil" },
    { title: "Sair" },
  ];

  notificationCount = 0;

  selectedCanteiro = localStorage.getItem("local_id");
  dropdownList = [];

  tipofuncCookie = ''

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    userService.getChangedName.subscribe((result) => this.changeName());
    userService.getNotificationCount.subscribe(
      (result) => (this.notificationCount = result)
    );

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title == "Sair") {
        this.logout();
      }
    });

    this.tipofuncCookie = localStorage.getItem('tipo_func');
  }
  private changeName(): void {
    var username = localStorage.getItem("nome");
    var userphoto = localStorage.getItem("photo");
    this.user = {
      name: username ? username : "User",
      picture: userphoto ? userphoto : "assets/images/userdefault.png",
    };
  }
  ngOnInit() {
    this.changeName();
    if (window.innerWidth > 768) {
      setTimeout(() => {
        this.toggleSidebar();
      }, 1000);
    }
    this.buscaCanteiro();
  }

  buscaCanteiro() {
    this.userService.getLocal().subscribe((val: any) => {
      {
        if (val.body && val.body.data) {
          this.dropdownList = val.body.data.locals.map((serv) => {
            return { item_id: serv.id, item_text: serv.nome };
          });
        }
      }
    });
  }

  onChangeCanteiro(e: any) {
    const selectedItemId = e.target.value;

    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key) && key === "local_id") {
        localStorage.removeItem(key);
      }
    }

    localStorage.setItem("local_id", selectedItemId);
    location.reload();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, "settings-sidebar");
    return false;
  }
  logout() {
    localStorage.setItem("token", "");
    localStorage.setItem("empresa_id", "");
    localStorage.setItem("nome", "");
    localStorage.setItem("email", "");
    localStorage.setItem("photo", "");
    localStorage.setItem("roles", "");
    localStorage.setItem("tipo_func", "");
    //this.router.navigate(['']);
    document.location.href = this.baseUrl;
  }
  goToHome() {
    this.menuService.navigateHome();
  }

  goToNoti() {
    this.router.navigate(["pages/perfil/notificacoes"]);
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }
}
